import axios from "axios";

export default function authHeader(){
    let user = JSON.parse(localStorage.getItem('user'));

    if(user && user.token){
        return {Authorization: 'Bearer ' + user.token, 'x-vars-version': 2}
    }else{
        return {};
    }
}

// async function checkTokenExpiration(user){
//     const response = await axios.post('https://api.varsanpr.com/api/auth/refresh', {}, {
//         headers: {
//             'x-vars-version': 2,
//             "x-vars-refresh-token": user.refreshToken,
//             Authorization: 'Bearer ' + user.token,
//         }
//     });
//     if (response.data.refreshToken) {
//         user.refreshToken = response.data.refreshToken;
//     }
//     if (response.data.accessToken) {
//         user.token = response.data.accessToken;
//     }
//     localStorage.setItem('user', JSON.stringify(user));
// };